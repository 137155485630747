exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-criacao-de-logo-js": () => import("./../../../src/pages/criacao-de-logo.js" /* webpackChunkName: "component---src-pages-criacao-de-logo-js" */),
  "component---src-pages-design-redes-sociais-js": () => import("./../../../src/pages/design-redes-sociais.js" /* webpackChunkName: "component---src-pages-design-redes-sociais-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logo-para-cafe-js": () => import("./../../../src/pages/logo-para-cafe.js" /* webpackChunkName: "component---src-pages-logo-para-cafe-js" */),
  "component---src-pages-obrigado-orcamento-js": () => import("./../../../src/pages/obrigado-orcamento.js" /* webpackChunkName: "component---src-pages-obrigado-orcamento-js" */),
  "component---src-pages-por-que-me-contratar-js": () => import("./../../../src/pages/por-que-me-contratar.js" /* webpackChunkName: "component---src-pages-por-que-me-contratar-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-sobre-mim-js": () => import("./../../../src/pages/sobre-mim.js" /* webpackChunkName: "component---src-pages-sobre-mim-js" */),
  "component---src-pages-solicitar-orcamento-js": () => import("./../../../src/pages/solicitar-orcamento.js" /* webpackChunkName: "component---src-pages-solicitar-orcamento-js" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-portfolio-item-jsx": () => import("./../../../src/templates/portfolio-item.jsx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx" */),
  "component---src-templates-redirect-jsx": () => import("./../../../src/templates/redirect.jsx" /* webpackChunkName: "component---src-templates-redirect-jsx" */)
}

